<template>
  <div>
    <!-- <Handle type="target" :position="Position.Top" /> -->
    <div class="custom-node nodes add-new-trigger" v-if="data?.type == 'addNewTrigger' && data?.add">
      <span class="custom-node-label d-flex align-items-center">
        <span class="btn mb-0 add-trigger-btn me-2">
          <i :class="data.icon"></i>
        </span>
        <span class="text-ellipsis"> {{ data.title || label }}</span>
      </span>
    </div>
    <div class="custom-node nodes" :id="'node_'+id" :class="selectedData?.id == id ? 'active' : ''" v-else-if="data?.type == 'addNewTrigger' && !data?.add">
      <span class="custom-node-label d-flex align-items-center">
        <span class="btn mb-0 bg-light add-trigger-btn me-2"> <i :class="data.icon"></i></span>
        <span class="text-ellipsis custom-node-label-inner">
          <div class="text-custom font-600">Trigger</div>
          <div class="text-break" style="font-size:12px">{{ data.title || label }}</div>
        </span>
      </span>
    </div>
    <div class="custom-node end-trigger" v-else-if="data?.type == 'EndTrigger'">
      <span class="custom-node-label">
        <span class="border custom-node-label-inner" style="padding: 4px 14px; border-radius: 40vh"> {{ label }}</span>
      </span>
    </div>
    <div v-else class="custom-node position-relative" :id="'node_'+id"  :class="data?.type == 'addNewAction' && data?.add ? 'add-action' : selectedData?.id == id ? 'active nodes ' : 'nodes'">
      <span class="custom-node-label" style="" v-if="data?.type == 'addNewAction' && data?.add">
        <span class="bg-white bordercustom-node-label-inner d-flex align-items-center justify-content-center" style="width: 25px; height: 25px; border-radius: 50%"> 
          <i :class="data.icon"></i> </span>
      </span>
      <span class="custom-node-label d-flex align-items-center"  v-else>
        <span class="btn mb-0 add-trigger-btn me-2">
          <img style="max-width: 24px" src="@/assets/img/chatgpt.svg" v-if="data.name == 'chatgpt'" />
          <i v-else :class="data.icon"></i>
        </span>
        <span class="text-ellipsis custom-node-label-inner">
          {{ data.title || label }}
        </span>
        <popper v-if="!data?.add" placement="right" @close:popper="actionSelected = ''" class="ms-auto" @click="$event.stopPropagation()">
          <i class="fa action-btn fa-ellipsis-h ms-auto"></i>
          <template #content="{ close }">
            <div class="popover-content node-manage-options bg-white rounded-2 inbox-nav-manage-options shadow" ref="popoverContent">
              <div class="col-12 container-fluid p-0">
                <div class="list-unstyled text-xs px-0 p-1 mb-0 popli">
                  <div class="px-0 mx-0 border-bottom">
                    <div class="px-2 py-1 d-flex align-items-center justify-content-between">
                      <a class="text-col-navlink text-xs text-nowrap text-ellipsis" href="javascript:void(0)">Actions</a>
                      <i
                        class="fa fa-times ms-2"
                        @click="
                          actionSelected = '';
                          close();
                        "></i>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="px-0 mx-0" @click="actionSelected = 'delete'">
                      <div class="py-2 px-3 d-flex align-items-center justify-content-between hover-highlight" style="min-width: 100px">
                        <a class="text-col-navlink text-xs text-nowrap text-ellipsis" href="javascript:void(0)">Delete</a>
                        <i class="fa fa-chevron-right text-xs ms-2"></i>
                      </div>
                    </div>
                    <div v-if="actionSelected == 'delete'" class="px-0 mx-0">
                      <div
                        class="py-2 px-3 d-flex align-items-center justify-content-between hover-highlight"
                        @click="
                          $emit('deleteAction', id);
                          close();
                        ">
                        <a class="text-col-navlink text-xs text-nowrap text-ellipsis" href="javascript:void(0)">Delete Action</a>
                      </div>
                      <div
                        class="py-2 px-3 d-flex align-items-center justify-content-between hover-highlight"
                        @click="
                          $emit('deleteAllAction', id);
                          close();
                        ">
                        <a class="text-col-navlink text-xs text-nowrap text-ellipsis" href="javascript:void(0)">Delete All Actions From Here</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popper>
      </span>
    </div>
    <!-- <Handle type="source" :position="Position.Bottom" /> -->
  </div>
</template>

<script>
  export default {
    name: 'CustomNode',
    props: ['id', 'data', 'label', 'selectedData', 'name', 'position'],
    emits: ['deleteAction', 'deleteAllAction'],
    data() {
      return {
        actionSelected: '',
      };
    },
  };
</script>

<style scoped>
  .custom-node {
    font-size: 14px;
    padding: 14px 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
  }
  .custom-node.nodes {
    width: 250px;
  }
  .custom-node-label {
    font-size: 14px;
  }
  ::v-deep .custom-node .popper {
    /* margin-top: 5px!important; */
    /* margin-left: 50px!important; */
    transform: translate(262px, 18px) !important;
  }
  ::v-deep .popper .hover-highlight:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
    border-radius: 4px !important;
  }
  .add-new-trigger {
    border: 1px dashed #2463eb !important;
    color: #2463eb;
    font-weight: 600;
    background: #eef6ff;
  }
  .add-trigger-btn i {
    color: #2463eb;
  }
  .add-new-trigger .add-trigger-btn {
    background-color: #dbe9fe !important;
  }
  .add-action {
    height: 25px;
    width: 250px;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end-trigger {
    width: 250px;
    background: transparent;
    border: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .end-trigger .custom-node-label-inner {
    background: #d1d5da !important;
  }
  .text-custom {
    color: #2463eb;
  }
  .font-600 {
    font-weight: 600;
  }
  .add-trigger-btn {
    width: 40px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #f1f4f7;
    font-size: 16px;
  }
  .action-btn {
    color: #9ba3af;
  }
  .active {
    border: 1px solid #2463eb !important;
  }
</style>
