<template>
  <div class="row align-items-center text-sm">
    <div class="col-xl-3  text-start d-none d-xl-block"></div>
    <div class="col col-sm-12 col-md-7 col-lg-6 col-xl-6 text-start text-md-start p-0">
      <ul class="nav nav-pills justify-content-center pt-0">
        <li class="nav-item">
          <a class="nav-link mx-4 text-primary" :class="{ active: activeTab == 'builder' }"
            @click="$emit('changeTab', 'builder'),$store.state.goToAction = '', changeTab('builder')" href="#">Builder</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mx-4" href="#" v-if="!parent_lib_id" :class="{ active: activeTab == 'settings' }"
            @click="$emit('changeTab', 'settings'), $store.state.goToAction = '', changeTab('settings')">Settings</a>
        </li>
        <!-- <li class="nav-item">
              <a class="nav-link mx-4" href="#">Enrolment History</a>
            </li> -->
        <li class="nav-item">
          <a class="nav-link mx-4" href="#" :class="{ active: activeTab == 'executionhistory' }"
            @click="$emit('changeTab', 'executionhistory'), $store.state.goToAction = '', changeTab('executionhistory')">Enrollment History</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mx-4" href="#" :class="{ active: activeTab == 'executionlogs' }"
            @click="$emit('changeTab', 'executionlogs'), $store.state.goToAction = '', changeTab('executionlogs')">Execution Logs</a>
        </li>
      </ul>
    </div>
    <div class="col-12 col-md-5 col-lg-6 col-xl-3 text-center text-md-end">
      <!-- <button class="btn btn-light text-sm my-1 p-3 py-1 text-normal">Test Automation</button> -->
      <div class="form-check form-switch d-inline-flex align-items-center">
        <label class="form-check-label me-2 text-sm p-0 m-0" for="flexSwitchCheckDefault">Draft</label>
        <input class="form-check-input mx-2 draft-publish" type="checkbox" id="flexSwitchCheckDefault"
          @change="$emit('stepAdded'), $emit('is_enabled', $event.target.checked)" :checked="is_enabled" />
        <label class="form-check-label text-sm" for="flexSwitchCheckDefault">Publish</label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkflowTabs',
    props: ['activeTab', 'parent_lib_id', 'is_enabled', 'queryParamsObject'],
    emits: ['changeTab', 'is_enabled', 'stepAdded'],
    methods: {
      changeTab(activeTab) {
        let query = {redirecturl:decodeURIComponent(this.queryParamsObject?.redirecturl), activetab:activeTab}
        if(this.queryParamsObject?.execution_history_id) {
          query = {...query, execution_history_id:this.queryParamsObject?.execution_history_id}
        }
        if(this.queryParamsObject?.execution_history_email) {
          query = {...query, execution_history_email:this.queryParamsObject?.execution_history_email}
        }
        if(this.queryParamsObject?.logs_conversation_filter) {
          query = {...query, logs_conversation_filter:this.queryParamsObject?.logs_conversation_filter}
        }
        window.parent.postMessage(
          { type: 'query', query:query}, 
          '*'
        );
      },
    }
  };
</script>
