<template>
  <div class="p-5 bg-light mx-auto" style="height: calc(100vh - 100px); transition: 0.5s; width: 768px; z-index: 999; right: 0">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="mb-4">Run Workflow For Child Agencies</h4>
    </div>
    <div class="form-group mb-3">
      <label>Run Workflows for </label>
      <select name="" id="" :value="run_for_child" @change="$emit('change_run_for_child', $event.target.value)" class="form-select">
        <option value="">None</option>
        <option value="all">All</option>
        <option value="selected">Selected Agencies</option>
      </select>
    </div>
    <div class="form-group mb-3 select-child-agencies" v-if="run_for_child == 'selected'">
      <label>Select Child Agencies </label>
      <MultiSelect
        :loading="isChildAgenciesLoading"
        v-model="agencies"
        display="chip"
        :options="childAgenciesList"
        optionLabel="library_domain"
        optionValue="support_lib_id"
        filter
        :placeholder="isChildAgenciesLoading ? 'Loading...' : 'Select Child Agencies'"
        class="w-100 p-0" />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'WorkflowSettings',
    props: ['run_for_child', 'childAgenciesList', 'isChildAgenciesLoading', 'child_agencies'],
    emits: ['change_run_for_child', 'child_agencies'],
    data() {
      return {
        agencies: [],
      };
    },
    mounted() {
      this.agencies = this.child_agencies;
    },
    watch: {
      agencies: {
        deep: true,
        handler() {
          this.$emit('child_agencies', this.agencies);
        },
      },
    },
  };
</script>
