// src/store/index.js
import { createStore } from 'vuex';

import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

const store = createStore({
  state: {
    bootstrap,
    // Define your application state here
    fitViewParams: {padding:1.25, offset:{x:0, y:-120}, duration:500 },
    showToast:{},
    goToAction:''
  },
  mutations: {
    // Define mutations to change the state
   
  },
  actions: {
    // Define actions to perform operations, potentially async
   
  },
  getters: {
    // Define getters to access state
    
  }
});

export default store;
