<template>
    <path
      :id="`trigger-${source}-${target}-edge`"
      :d="pathData"
      class="vue-flow__edge-path"
      marker-end="url('#')"
      marker-start="url('#')"
    />
    <path
      fill="none"
      :d="pathData"
      stroke-width="20"
      stroke-opacity="0"
      class="vue-flow__edge-interaction"
    />
</template>

<script>
export default {
    props: {
        source: {
            type: String,
            required: true,
        },
        target: {
            type: String,
            required: true,
        },
        nodes: {
            type: Array,
            default: () => [],
        },
        strokeWidth: {
            type: Number,
            default: 2,
        },
    },
    computed: {
        pathData() {
            if (!this.nodes || this.nodes.length === 0) {
                console.warn('Nodes array is empty or undefined');
                return ''; 
            }
            const sourceNode = this.nodes.find(node => node.id === this.source);
            const targetNode = this.nodes.find(node => node.id === this.target);

            if (!sourceNode || !targetNode) {
                console.warn('Source or target node not found');
                return ''; 
            }

            const { position: { x: x1, y: y1 } } = sourceNode;
            const { position: { x: x2, y: y2 } } = targetNode;

            const controlPointOffset = 30; // Adjust this value for curvature
            const yOffset = x1>x2 ? 30.5 : x1==x2 ? 12.5 : x1<x2 ? -10 : 0;
            
            // Create the path data for the Bezier curve

            // return  `M${x1+125} ${y1+75}
            //         L${x1+125} ${y1+75}
            //         L${x1+125} ${y1+90.5}
            //         Q ${x1+125},${y2-10} ${x2+112.5+yOffset},${y2-10}
            //         Q ${x2+controlPointOffset+95},${y2-10} ${x2+controlPointOffset+95},${y2}
            //         L${x2+controlPointOffset+95} ${y2}
            //         L${x2+controlPointOffset+95} ${y2}`;

            return  `M${x1+125} ${y1}
                    L${x1+125} ${y1}
                    L${x1+125} ${y1+90.5}
                    Q ${x1+125},${y2-10} ${x2+112.5+yOffset},${y2-10}
                    Q ${x2+controlPointOffset+95},${y2-10} ${x2+controlPointOffset+95},${y2}
                    L${x2+controlPointOffset+95} ${y2}
                    L${x2+controlPointOffset+95} ${y2}`;

                    
        }   
        // Create the path data for the curly braces
       
    },
};
</script>

<style scoped>
/* Add styles if necessary */
</style>
