<template>
  <div class="row align-items-center border-bottom pt-2 pb-2">
    <div class="col-md-4">
      <button @click="backToWorkflows" class="btn btn-link custom-link d-flex align-items-center mb-0"><i class="fa-solid fa-chevron-left fs-5 me-2"></i> Back to Automations</button>
    </div>
    <!-- <div class="col-md-4 text-center workflow-name">
      <span class="p-2 py-1 fs-6" id="workflowName" contenteditable="true" v-html="workflowName"></span>
      <i class="fa-solid fa-pencil-mechanical ms-1"></i>
    </div> -->
    <div class="col-md-4 text-center workflow-name">
      <span class="p-2 py-1 fs-6" id="workflowName" contenteditable="true" v-html="displayedWorkflowName"></span>
      <i class="fa-solid fa-pencil-mechanical ms-1"></i>
    </div>
    <div class="col-md-4 text-end d-flex align-items-center justify-content-end">
      <!-- <button class="btn btn-light rounded-all mb-0 p-3 py-1 btn-sm">
        <i class="fa-regular fa-clock mb-0"></i>
      </button> -->
      <button class="btn btn-primary ms-2 p-3 py-1 mb-0 btn-sm" :disabled="isErrors" @click="saveFlow">
        <span v-if="isSavingWorkflow">Saving... <i class="fa fa-circle-o-notch fa-spin"></i></span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkflowHeader',
    props: {
      workflowName: {
        type: String,
        required: true,
      },
      isErrors: {
        type: Boolean,
        required: false,
      },
      isSavingWorkflow: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['stepAdded'],
    watch: {
      workflowName: {
        handler() {
          this.$emit('stepAdded');
        },
      },
    },
    computed: {
      displayedWorkflowName() {
        return this.workflowName.trim() === '' ? 'Untitled Workflow' : this.workflowName;
      },
    },
    methods: {
      backToWorkflows() {
        this.$emit('back-to-workflows');
      },
      saveFlow() {
        if (!this.isErrors) {
          this.$emit('save-flow');
        }
      },
    },
  };
</script>

<style>
  .btn-primary {
    background-color: var(--primary-600) !important;
    color: var(--base-white) !important;
    border-color: var(--primary-600) !important;
  }

  .custom-link {
    color: #333 !important;
    text-decoration: none !important;
    font-weight: normal !important;
  }

  .custom-link:hover {
    color: black !important;
    text-decoration: none !important;
  }
</style>
