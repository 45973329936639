<template>
  <div class="workflow-viewport">
    <div
      class="position-fixed bg-light action-list shadow-sm"
      :class="{
        ' action-list-alert': is_alert == 'on',
        'action-list-36': selectedFlow === 'addNewTrigger',
      }"
      :style="isOpen ? 'right: 0;' : 'right:-50%'"
    >
      <ConditionForm
        :key="renderKey"
        :ticket_statuses="ticket_statuses"
        :flowTitle="flowTitle"
        :selectedNode="selectedData"
        :showSubPanel="showSubPanel"
        @isOpen="
          $emit('isOpen', $event),
            $event == false ? $emit('isErrors', false) : ''
        "
        :selectedFlow="selectedFlow"
        :isUpdate="isUpdate"
        :isOpen="isOpen"
        @resetPanel="resetPanel()"
        @flowTitle="flowTitle = $event"
        @selectWorkflow="selectWorkflow($event)"
      
        @showSubPanel="showSubPanel = $event"
       
       
        @addFlow="addFlow($event)"
        @deleteFlow="deleteFlow()"
        @isErrors="$emit('isErrors', $event)"
        @deleteBranchCondition="deleteBranchCondition($event)"
        @removeBranch="removeBranch($event)"
      />
    </div>
    <VueFlow
      @pane-ready="$emit('onPaneReady', $event)"
      :nodes="nodes"
      :edges="edges"
      :key="renderKey"
      @nodeClick="nodeClick"
      @connect="handleConnect"
      class="interactionflow"
    >
      <template #edge-custom="customEdgeProps">
        <CustomEdge v-bind="customEdgeProps" :nodes="nodes" />
      </template>
      <template #node-custom="props">
        <CustomBranch
          v-if="props?.data?.isBranch"
          :selectedData="selectedData"
          v-bind="props"
        />
        <CustomNode
          v-else
          :selectedData="selectedData"
          v-bind="props"
          @deleteAction="deleteActionFlow($event)"
          @deleteAllAction="deleteActionFlow($event, 'all')"
        />
      </template>
      <Background
        patternColor="var(--mi-chat-secondary-bg-color)"
        gap="10"
        size="1.5"
      />
      <Controls position="bottom-left" class="cbtns" :showInteractive="false">
      </Controls>
      <MiniMap pannable zoomable maskColor="#FFFFFF" class="shadow" />
    </VueFlow>
  </div>

  <div class="position-absolute bottom-0 left-0 controlbuttons d-none">
    <div class="d-flex">
      <button
        class="btn ms-1 btn-white w-10"
        tabindex="0"
        type="button"
        id="workflow-fit-to-screen"
        title="Fit to Screen"
        linkgray="false"
      >
        <span class="">
          <i class="fa-regular fa-arrows-maximize"></i>
        </span>
      </button>
      <button
        class="btn btn-white ms-1 rounded-end-0 rounded-right-0 w-10"
        tabindex="0"
        type="button"
        id="workflow-zoom-in"
        title="Zoom In"
        linkgray="false"
      >
        <span class="">
          <i class="fas fa-plus"></i>
        </span>
      </button>
      <button
        class="btn btn-white mx-0 btn-white-center w-14"
        tabindex="0"
        type="button"
        id="workflow-zoom-value"
        linkgray="false"
      >
        <span class="">100%</span>
      </button>
      <button
        class="btn btn-white me-1 w-10 rounded-start-0"
        tabindex="0"
        type="button"
        id="workflow-zoom-out"
        title="Zoom Out"
        linkgray="false"
      >
        <span class="">
          <i class="fas fa-minus"></i>
        </span>
      </button>
    </div>
  </div>
</template>
<script>
import { VueFlow, useVueFlow } from "@vue-flow/core";
import "@vue-flow/core/dist/style.css";
import "@vue-flow/core/dist/theme-default.css";
import { Controls } from "@vue-flow/controls";
import "@vue-flow/controls/dist/style.css";
import ConditionForm from "./ConditionForm.vue";
import { MiniMap } from "@vue-flow/minimap";
import { Background } from "@vue-flow/background";
import "./workflow.css";
import CustomNode from "./CustomNode.vue";
import CustomBranch from "./CustomBranch.vue";
import CustomEdge from "./CustomEdge.vue";
import { useToast } from "vue-toastification";
import moment from "moment";
import _ from "lodash";
import { useStore } from "vuex";
export default {
  name: "WorkflowViewport",
  components: {
    VueFlow,
    Controls,
    ConditionForm,
    MiniMap,
    Background,
    CustomBranch,
    CustomNode,
    CustomEdge,
  },
  props: [
    "ticket_statuses",
    "activeTab",
    "is_enabled",
    "workflowNodes",
    "workflowEdges",
    "renderKey",
    "isErrors",
    "isOpen",
    "isLoadingWorkflows",
    "support_lib_id",
    "parent_lib_id",
    "ref_uid",
    "workflowJson",
    "instance",
    "stepAdded",
  ],
  emits: [
    "workflow",
    "isOpen",
    "onPaneReady",
    "isErrors",
    "renderKey",
    "resetFlow",
    "stepAdded",
    "nodes",
    "edges",
    "connectEdges",
    "centerAllTriggers",
    "selectedFlow",
  ],
  data() {
    return {
      defaultXPosition: 450,
      defaultYPosition: 0,
      addYPosition: 125,
      isBranchData: null,
      showSubPanel: false,
      flowTitle: "",
      selectedData: {},
      selectedNodeId: "",
      nextNodeId:'',
      selectedparentNodeId: "",
      isUpdate: false,
      selectedFlow: "",
      nodes: [],
      edges: [],
      workflow: {},
      is_alert: "",
    };
  },
  setup() {
    const { onInit, nodesDraggable } = useVueFlow();
    nodesDraggable.value = false;
    const store = useStore();
    onInit((vueFlowInstance) => {
      vueFlowInstance.fitView(store.state.fitViewParams);
    });
    const toast = useToast();
    return {
      toast,
    };
  },
  methods: {
    handleConnect(e) {
      const newEdge = {
        id: `e-${this.edges.length + 1}`,
        source: e.source,
        target: e.target,
      };
      this.edges.push(newEdge);
    },
    resetFlow() {
      this.$emit("resetFlow");
      this.workflow.trigger.events = [];
      this.workflow.actions = [];
      this.resetPanel();
    },

    deleteActionFlow(id, type) {
      if (type) {
        let index = this.nodes.findIndex((el) => el.id == id);
        if (index !== -1) {

          this.$swal({
            iconHtml: `
                  <i class="fa fa-trash text-danger custom-popup-icon"></i> 
                  <i id="custom-close-btn" class="custom-close-btn fa fa-times text-sm cursor-pointer" aria-label="Close"> </i>
              `, // Adding an icon similar to the trash bin
            title: "Are you sure you want to delete this step?",
            text: "The changes will only take effect when you save this workflow.",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
            customClass: {
              popup: "custom-swal-popup", // Custom class for the modal
              title: "custom-swal-title px-0", // Custom class for the title
              htmlContainer: "custom-swal-text px-0 m-0", // Custom class for text content
              actions: "custom-swal-actions",
              cancelButton: "btn btn-outline-secondary border", // Custom class for cancel button
              confirmButton: "btn btn-danger", // Custom class for confirm button
            },
            buttonsStyling: false,
            didOpen: () => {
              // Add a click event listener to the custom close button to close the modal
              document
                .getElementById("custom-close-btn")
                .addEventListener("click", () => {
                  this.$swal.close(); // Close the SweetAlert modal
                });
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              let nodeToBeDeleted = this.nodes
                .slice(index)
                .filter(
                  (el) =>
                    !["EndTrigger", "addNewTrigger"].includes(el.data.type)
                );
              console.log('type :- ', type)
              console.log('nodeToBeDeleted :- ', nodeToBeDeleted)
              nodeToBeDeleted.forEach((elem) => {
                this.selectedData = { ...elem };
                this.deleteFlow();
              });
            }
          }
          )
        }
      } else {
        let findNode = this.nodes.find((el) => el.id == id);
        if (findNode) {
          this.$swal({
            iconHtml: `
                    <i class="fa fa-trash text-danger custom-popup-icon"></i> 
                    <i id="custom-close-btn" class="custom-close-btn fa fa-times text-sm cursor-pointer" aria-label="Close"> </i>
                `, // Adding an icon similar to the trash bin
            title: "Are you sure you want to delete this step?",
            text: "The changes will only take effect when you save this workflow.",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
            customClass: {
              popup: "custom-swal-popup", // Custom class for the modal
              title: "custom-swal-title px-0", // Custom class for the title
              htmlContainer: "custom-swal-text px-0 m-0", // Custom class for text content
              actions: "custom-swal-actions",
              cancelButton: "btn btn-outline-secondary border", // Custom class for cancel button
              confirmButton: "btn btn-danger", // Custom class for confirm button
            },
            buttonsStyling: false,
            didOpen: () => {
              // Add a click event listener to the custom close button to close the modal
              document
                .getElementById("custom-close-btn")
                .addEventListener("click", () => {
                  this.$swal.close(); // Close the SweetAlert modal
                });
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.selectedData = { ...findNode };
              this.deleteFlow();
            }
          }
          )
        }
      }
    },

    removeNodeAndDescendants(nodeId) {
      // Find the index of the node with the given id
      let nodeIndex = this.nodes.findIndex((node) => node.id === nodeId);
      if (nodeIndex !== -1) {
        let node = this.nodes[nodeIndex];
        let indicesToRemove = [];
        if (node.name == "condition") {
          node.branches.forEach((branch) => {
            // console.log('branch.id ', branch.id )
            this.nodes.forEach((n, j) => {
              if (n.data.branchId == branch.id) {
                this.removeNodeAndDescendants(n.id);
                indicesToRemove.push(j);
              }
            });
          });
        }

        indicesToRemove.sort((a, b) => b - a);
        indicesToRemove.forEach((index) => {
          this.nodes.splice(index, 1);
        });
        this.nodes.splice(nodeIndex, 1);
        this.$emit("renderKey");
        this.$emit("stepAdded");
      }
    },

    addEndTrigger() {
      let endActionNode = this.nodes.find(
        (n) => n.data.type === "EndTrigger" && n.data.add
      );
      let addActionNodes = this.nodes.filter(
        (n) => n.data.type === "addNewAction"
      );
      if (!endActionNode) {
        const id = moment().valueOf().toString();
        this.nodes.push({
          id: id,
          type: "custom",
          label: "END",
          position: {
            x: this.defaultXPosition,
            y:
              addActionNodes[addActionNodes.length - 1]?.position?.y + 100 ||
              200,
          },
          data: {
            label: "END",
            type: "EndTrigger",
            add: true,
          },
        });
      }
    },

    deleteFlow() {
      const nodeId = this.selectedData?.id;
      const findChildIndex = this.nodes.findIndex(
        (el) => el?.data?.parentId == nodeId
      );
      if (findChildIndex !== -1) {
        console.log("findChildIndex ", findChildIndex);
        if (!this.nodes[findChildIndex].data.branchId) {
          let elseExecuted = false;
          this.nodes
            .filter((n) => !n.data.branchId)
            .forEach((node, i) => {
              if (
                !node.data.branchId &&
                node.data.type !== "addNewTrigger" &&
                i > findChildIndex
              ) {
                node.position.y =
                  node.data.type == "EndTrigger"
                    ? node.position.y - 280
                    : node.position.y - 300;
              } else {
                if (!elseExecuted) {
                  elseExecuted = true;
                  if (this.nodes.some((elem) => elem.data.branchId)) {
                    this.nodes.forEach((el) => {
                      if (el.name == "condition") {
                        el.branches.forEach((branch) => {
                          let branchChild = this.nodes.filter(
                            (f) => f.data.branchId == branch.id
                          );
                          branchChild.forEach((n) => {
                            console.log("branchChild ", n.position.y);
                            n.position.y = n.position.y - this.addYPosition;
                          });
                        });
                      }
                    });
                  }
                }
              }
            });
        }
      }

      // Find and remove the node
      const nodeIndex = this.nodes.findIndex((el) => el.id === nodeId);
      if (nodeIndex !== -1) {
        const deletedNode = this.nodes[nodeIndex];
        console.log("deletedNode ", deletedNode);
        if (deletedNode.name == "condition") {
          this.removeNodeAndDescendants(this.nodes[nodeIndex].id);
          if (!this.nodes.find((el) => el.data.branchId)) {
            this.addEndTrigger();
          } else {
            let endActionNode = this.nodes.find(
              (n) =>
                n.data.type === "EndTrigger" &&
                n.data.branchId == deletedNode.data.branchId &&
                n.data.add
            );
            let addActionNodes = this.nodes.filter(
              (n) =>
                !!n.data.branchId &&
                n.data.branchId == deletedNode.data.branchId
            );
            if (!endActionNode) {
              this.nodes.push({
                id: `endActionPayload` + moment().valueOf(),
                type: "custom",
                label: "END",
                position: {
                  x: deletedNode.position.x,
                  y:
                    addActionNodes[addActionNodes.length - 1]?.position?.y +
                      this.addYPosition || 200,
                },
                data: {
                  parentId: addActionNodes[addActionNodes.length - 2].id,
                  branchId: deletedNode.data.branchId,
                  isBranch: true,
                  label: "END",
                  type: "EndTrigger",
                  add: true,
                },
              });
            }
          }
        } else {
          if (findChildIndex !== -1) {
            this.nodes.splice(findChildIndex, 1);
          }
          this.nodes.splice(nodeIndex, 1);
          if (!this.nodes.find((el) => el.data.branchId)) {
            this.addEndTrigger();
          }
        }

        const nodesInBranch = this.nodes;
        nodesInBranch.forEach((el) => {
          if (
            el.data.type === "addNewAction" ||
            el.data.type === "EndTrigger"
          ) {
            if (el.name === "condition") {
              el.branches.forEach((branch) => {
                let branchChild = this.nodes.filter(
                  (f) => f.data.branchId == branch.id
                );
                branchChild.forEach((elem, idx) => {
                  elem.position.y =
                    (this.nodes.find((e) => e.data.branchId == branch.id)
                      ?.position?.y || 0) +
                    this.addYPosition * idx;
                });
              });
            }
          }
        });

        // Remove the node from the workflow
        let workflowTriggerIndex = this.workflow.trigger.events.findIndex(
          (el) => el.id == this.selectedData?.id
        );
        if (workflowTriggerIndex !== -1) {
          this.workflow.trigger.events.splice(workflowTriggerIndex, 1);
        } else {
          if (deletedNode?.data?.branchId) {
            if (this.selectedData?.name == "condition") {
              // Handle the case where selectedData is 'condition' and a branchId is provided
              const removeBranchAtIndex = (
                branches,
                branchesToBeDeleted
              ) => {
                branchesToBeDeleted.forEach((el) => {
                  let idx = branches.findIndex((n) => n.id == el.id);
                  if (idx !== -1) {
                    // Remove the branch at the current level
                    branches.splice(idx, 1);
                  } else {
                    // If not found at the current level, check deeper levels recursively
                    branches.forEach((branch) => {
                      if (branch.branches && branch.branches.length > 0) {
                        removeBranchAtIndex(branch.branches, [el]);
                      }
                    });
                  }
                });
              };

              // Start removing branches from the root, going from deep to shallow in the hierarchy
              removeBranchAtIndex(
                this.workflow.branches,
                deletedNode.branches
              );
            } else {
              // Handle the case where selectedData is not 'condition' but branchId is provided
              const removeActionAtIndex = (branches, branchId) => {
                for (let i = 0; i < branches.length; i++) {
                  if (branches[i].id === branchId) {
                    // Update actions of this branch
                    // console.log('actionPayload ', actionPayload)
                    let idx = branches[i].actions.findIndex(
                      (n) => n.id == this.selectedData.id
                    );
                    console.log("idx ", idx);
                    if (idx !== -1) {
                      branches[i].actions.splice(idx, 1); // update action
                    }

                    return true; // Action added, no need to continue searching
                  }
                  if (branches[i].branches) {
                    // Recursively search nested branches
                    if (
                      removeActionAtIndex(branches[i].branches, branchId)
                    ) {
                      return true; // Branch found and action added
                    }
                  }
                }
                return false; // Branch not found at this level
              };
              // Start updating actions from the root
              removeActionAtIndex(
                this.workflow.branches,
                deletedNode.data.branchId
              );
            }
          } else if (this.selectedData?.name === "condition") {
            let workflowActionIndex =
              this.workflow.branches[0].branches.findIndex(
                (el) => el.id == this.selectedData?.id
              );
            if (workflowActionIndex !== -1) {
              this.workflow.branches[0].branches.splice(
                workflowActionIndex,
                1
              );
            }
          } else {
            let workflowActionIndex =
              this.workflow.branches[0].actions.findIndex(
                (el) => el.id == this.selectedData?.id
              );
              console.log('workflowActionIndex ', workflowActionIndex)
            if (workflowActionIndex !== -1) {
              this.workflow.branches[0].actions.splice(
                workflowActionIndex,
                1
              );
            }
            console.log('this.workflow ', this.workflow)
          }
        }

        this.$emit("renderKey");
        this.$emit("stepAdded");
        this.$emit("nodes", this.nodes);
        this.$emit("connectEdges", "");
        this.resetPanel();
      }
    },

    getUpdatedNodePayload(nodePayload) {
      if (this.selectedData?.name == "updateTicketStatus") {
        nodePayload = {
          ...nodePayload,
          ticket_status_id: this.selectedData.ticket_status_id,
        };
      } else if (this.selectedData.name == "webhook") {
        nodePayload = {
          ...nodePayload,
          api_url: this.selectedData.api_url,
          method: this.selectedData.method,
          customData: this.selectedData.customData,
          headers: this.selectedData.headers,
        };
      } else if (this.selectedData.name == "fetchConversation") {
        nodePayload = {
          ...nodePayload,
          api_url: this.selectedData.api_url,
          sendToModalSupport: this.selectedData.sendToModalSupport,
          headers: this.selectedData.headers,
        };
      } else if (this.selectedData.name == "assignTicket") {
        nodePayload = {
          ...nodePayload,
          assignedUsers: this.selectedData.assignedUsers,
          roundRobin: this.selectedData.roundRobin,
        };
      } else if (this.selectedData.name == "addTag") {
        nodePayload = {
          ...nodePayload,
          selectedTags: this.selectedData.selectedTags,
        };
      } else if (this.selectedData.name == "removeTag") {
        nodePayload = {
          ...nodePayload,
          selectedTags: this.selectedData.selectedTags,
        };
      } else if (this.selectedData.name == "createNote") {
        nodePayload = {
          ...nodePayload,
          note: this.selectedData.note,
        };
      } else if (this.selectedData.name == "autoReply") {
        nodePayload = {
          ...nodePayload,
          subject: this.selectedData.subject,
          message: this.selectedData.message,
          replyType: this.selectedData.replyType,
        };
      } else if (
        ["redirectEmail", "forwardEmail"].includes(this.selectedData.name)
      ) {
        nodePayload = {
          ...nodePayload,
          email: this.selectedData.email,
        };
      } else if (["chatgpt", "gemini", "claude", "llama"].includes(this.selectedData.name)) {
        nodePayload = {
          ...nodePayload,
          model: this.selectedData.model,
          prompt: this.selectedData.prompt,
          temperature: +this.selectedData.temperature,
        };
        if(["chatgpt", "claude", "llama"].includes(this.selectedData.name)) {
          nodePayload = {
            ...nodePayload,
            top_p: +this.selectedData.top_p,
            max_tokens: +this.selectedData.max_tokens,
          }
        }
        if(['gemini'].includes(this.selectedData.name)) {
          nodePayload = {
              ...nodePayload,
              stopSequences:this.selectedData?.stopSequences,
              maxOutputTokens:+this.selectedData?.maxOutputTokens
            }
          }
        if(["claude"].includes(this.selectedData?.name)) {
          nodePayload = {
            ...nodePayload,
            top_k: this.selectedData.top_k,
          }
        }
      } else if (this.selectedData?.name == "condition") {
        nodePayload = {
          ...nodePayload,
          branches: this.selectedData.branches,
        };
      }
      return nodePayload;
    },

    getWorkflowTriggerPayload(nodePayload) {
      let workflowTriggerPayload = {
        id: nodePayload.id,
        type: this.selectedData.name,
        label: this.selectedData.label,
      };
      if (this.selectedData.name == "ticketCreated") {
        let payload = [];
        this.selectedData.filters.forEach((el) => {
          if (
            [
              "from",
              "to",
              "senderEmail",
              "senderName",
              "subject",
              "body",
              "isAssignedTo",
              "isReceivedOnDate",
              "receivedDay",
            ].includes(el?.type)
          ) {
            payload.push({
              field: el.type,
              value: el.value,
              operator: el.operator,
            });
          } else if (el.type == "timeWithinRange") {
            payload.push({
              field: el.type,
              operator: "between",
              value: [el.startTime, el.endTime],
            });
          } else if (el.type == "lastReplyAfter") {
            payload.push({
              field: el.type,
              operator: "before",
              value: el.value,
            });
          } else {
            payload.push({ field: el.type, value: el.value });
          }
        });
        workflowTriggerPayload = {
          ...workflowTriggerPayload,
          condition: payload,
        };
      } else if (this.selectedData.name == "onNewMessage") {
        let payload = [];
        this.selectedData.filters.forEach((el) => {
          if (
            [
              "from",
              "to",
              "senderEmail",
              "senderName",
              "subject",
              "body",
              "isAssignedTo",
              "isReceivedOnDate",
              "receivedDay",
              "channel",
              "channelType",
            ].includes(el?.type)
          ) {
            payload.push({
              field: el.type,
              value: el.value,
              operator: el.operator,
            });
          } else if (el.type == "timeWithinRange") {
            payload.push({
              field: el.type,
              operator: "between",
              value: [el.startTime, el.endTime],
            });
          } else if (el.type == "lastReplyAfter") {
            payload.push({
              field: el.type,
              operator: "before",
              value: el.value,
            });
          } else {
            payload.push({ field: el.type, value: el.value });
          }
        });
        workflowTriggerPayload = {
          ...workflowTriggerPayload,
          condition: payload,
        };
      } else if (this.selectedData?.name == "onChatEnded") {
        let payload = this.selectedData.filters.map((el) => {
          return { field: el.type, operator: el.operator, value: el.value };
        });
        workflowTriggerPayload = {
          ...workflowTriggerPayload,
          condition: payload,
        };
      } else if(this.selectedData?.name == 'conversationRated') {
        let payload = this.selectedData.filters.map((el) => {
          return { field: el.type, operator: el.operator, value: el.value };
        });
        workflowTriggerPayload = {
          ...workflowTriggerPayload,
          condition: payload,
        };
      }
      return workflowTriggerPayload;
    },

    getWorkflowActionPayload(nodePayload) {
      let workflowActionPayload = {
        id: nodePayload.id,
        label: this.selectedData.label,
        title: this.flowTitle,
        type: this.selectedData.name,
      };
      let parameters = {};
      if (this.selectedData?.name == "updateTicketStatus") {
        parameters["ticket_status_id"] = this.selectedData.ticket_status_id;
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData?.name == "webhook") {
        parameters["api_url"] = this.selectedData?.api_url;
        parameters["method"] = this.selectedData?.method;
        parameters["customdata"] = {};
        parameters["headers"] = {};
        this.selectedData.customData.forEach((el) => {
          parameters["customdata"][el.key] = el.value;
        });
        this.selectedData.headers.forEach((el) => {
          parameters["headers"][el.key] = el.value;
        });
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData.name == "fetchConversation") {
        parameters["headers"] = {};
        this.selectedData.headers.forEach((el) => {
          parameters["headers"][el.key] = el.value;
        });
        parameters["api_url"] = this.selectedData.api_url;
        parameters["sendToModalSupport"] = this.selectedData.sendToModalSupport;
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData.name == "assignTicket") {
        parameters["ticket_assigned_to"] = this.selectedData.roundRobin
          ? this.selectedData.assignedUsers.map((el) => el.ref_uid)
          : this.selectedData.assignedUsers.map((el) => el.ref_uid).toString();
        parameters["roundRobin"] = this.selectedData.roundRobin;
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData.name == "addTag") {
        parameters["added_by"] = this.ref_uid;
        parameters["tag"] = this.selectedData.selectedTags.map((el) => el.tag);
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData.name == "removeTag") {
        parameters["tag"] = this.selectedData.selectedTags.map((el) => el.tag);
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData.name == "createNote") {
        parameters["created_by"] = this.ref_uid;
        parameters["note"] = this.selectedData.note;
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (this.selectedData.name == "autoReply") {
        parameters["subject"] = this.selectedData.subject;
        parameters["message"] = this.selectedData.message;
        parameters["type"] = this.selectedData.replyType;
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (
        ["redirectEmail", "forwardEmail"].includes(this.selectedData.name)
      ) {
        parameters["email"] = this.selectedData.email;
        workflowActionPayload = { ...workflowActionPayload, parameters };
      } else if (["chatgpt", "gemini", "claude", "llama"].includes(this.selectedData.name)) {
        parameters["model"] = this.selectedData.model;
        parameters["prompt"] = this.selectedData.prompt;
        parameters["temperature"] = +this.selectedData.temperature;

        if(["chatgpt", "claude", "llama"].includes(this.selectedData.name)) {
          parameters["top_p"] = +this.selectedData.top_p;
          parameters["max_tokens"] = +this.selectedData.max_tokens;
        }

        if(['gemini'].includes(this.selectedData.name)) {
          parameters["stopSequences"] = this.selectedData.stopSequences;
          parameters["maxOutputTokens"] = +this.selectedData.stopSequences;
        }

        if(["claude"].includes(this.selectedData.name)) {
          parameters["top_k"] = +this.selectedData.top_k;
        }

        workflowActionPayload = { ...workflowActionPayload, parameters };
      }
      return workflowActionPayload;
    },

    updateAction() {
      let nodePayload = {};
      // console.log("this.flowTitle :- ", this.flowTitle);
      let index = this.nodes.findIndex((el) => el?.id == this.selectedData?.id);
      if (index !== -1) {
        console.log("this.nodes ", this.nodes[index]);
        nodePayload = {
          id: this.selectedData.id,
          type: "custom",
          name: this.selectedData.name,
          label: this.selectedData.label,
          data: {
            title: this.flowTitle,
            branchId: this.selectedData?.data?.branchId
              ? this.selectedData.data.branchId
              : null,
            type: this.selectedFlow,
            icon: this.selectedData?.data?.icon,
            add: false,
            name: this.selectedData.name,
          },
          filters: [...this.selectedData.filters],
          position: this.selectedData.position,
          hasFilters: this.selectedData.hasFilters,
          showAddFilterBtn: this.selectedData.showAddFilterBtn,
        };

        if (this.nodes[index]?.name == "condition") {
          nodePayload = {
            ...nodePayload,
            branches: this.selectedData.branches,
          };
          this.selectedData.branches.forEach((el, i) => {
            let filtered = this.nodes.filter(
              (n) => n.data.branchId == el.id && !n.add
            );
            filtered[0].name = this.selectedData.branches[i].branch_name;
            filtered[0].label = this.selectedData.branches[i].branch_name;
            filtered[0].data.name = this.selectedData.branches[i].branch_name;
            filtered[0].data.title = this.selectedData.branches[i].branch_name;
            console.log("filtered ", filtered);
            // if(idx !== -1) {
            //    this.nodes[idx].branch_name = el.branch_name
            //    this.nodes[idx].condition = el.condition
            // }
          });
        }

        nodePayload = this.getUpdatedNodePayload(nodePayload);

        console.log("payload ", nodePayload);

        this.nodes[index] = nodePayload;
        this.$emit("renderKey");
        this.$emit("stepAdded");
      }
      if (this.selectedData?.data?.type == "addNewTrigger") {
        let workflowTriggerIndex = this.workflow.trigger.events.findIndex(
          (el) => el.id == this.selectedData?.id
        );
        if (workflowTriggerIndex !== -1) {
          let workflowTriggerPayload =
            this.getWorkflowTriggerPayload(nodePayload);
          this.workflow.trigger.events[workflowTriggerIndex] =
            workflowTriggerPayload;
        }
      }
      if (this.selectedData?.data?.type == "addNewAction") {
        if (nodePayload?.data?.branchId) {
          if (this.selectedData?.name === "condition") {
            let newBranches = this.selectedData.branches.map((el) => ({
              id: el.id,
              branch_name: el.branch_name,
              condition: el.condition,
              actions: el.actions || [],
              branches: el.branches || [],
            }));
            // Handle the case where selectedData is 'condition' and a branchId is provided
            const updateBranchAtIndex = (branches, branchId) => {
              for (let i = 0; i < branches.length; i++) {
                if (branches[i].id === branchId) {
                  // Update branch with new payload
                  // let newBranchPayload = this.getWorkflowActionPayload(nodePayload);
                  branches[i] = newBranches;
                  return true; // Branch updated, no need to continue searching
                }
                if (branches[i].branches) {
                  // Recursively search nested branches
                  if (updateBranchAtIndex(branches[i].branches, branchId)) {
                    return true; // Branch found and updated
                  }
                }
              }
              return false; // Branch not found at this level
            };

            // Start updating branches from the root
            updateBranchAtIndex(
              this.workflow.branches[0].branches,
              nodePayload.data.branchId
            );
          } else {
            // Handle the case where selectedData is not 'condition' but branchId is provided
            const updateActionAtIndex = (branches, branchId) => {
              console.log("going in action inner ", branches);
              for (let i = 0; i < branches.length; i++) {
                if (branches[i].id === branchId) {
                  // Update actions of this branch
                  let actionPayload =
                    this.getWorkflowActionPayload(nodePayload);
                  // console.log('actionPayload ', actionPayload)
                  let idx = branches[i].actions.findIndex(
                    (n) => n.id == this.selectedData.id
                  );
                  if (idx !== -1) {
                    branches[i].actions[idx] = actionPayload; // update action
                  }

                  return true; // Action added, no need to continue searching
                }
                if (branches[i].branches) {
                  // Recursively search nested branches
                  if (updateActionAtIndex(branches[i].branches, branchId)) {
                    return true; // Branch found and action added
                  }
                }
              }
              return false; // Branch not found at this level
            };

            console.log("going in action");
            // Start updating actions from the root
            updateActionAtIndex(
              this.workflow.branches,
              nodePayload.data.branchId
            );
          }
        } else if (this.selectedData?.name == "condition") {
          // console.log('this.selectedData :- ', this.selectedData)
          this.selectedData.branches.forEach((el) => {
            let idx = this.workflow.branches[0].branches.findIndex(
              (n) => n.id == el.id
            );
            if (idx !== -1) {
              this.workflow.branches[0].branches[idx].branch_name =
                el.branch_name;
              this.workflow.branches[0].branches[idx].condition = el.condition;
            }
          });
        } else {
          let workflowActionIndex = this.workflow.branches[0].actions.findIndex(
            (el) => el.id == this.selectedData?.id
          );
          if (workflowActionIndex !== -1) {
            let workflowActionPayload =
              this.getWorkflowActionPayload(nodePayload);
            // console.log('workflowActionPayload', workflowActionPayload)
            this.workflow.branches[0].actions[workflowActionIndex] =
              workflowActionPayload;
          }
        }
      }
      // console.log('this.workflow ', this.workflow)
      this.$emit("workflow", this.workflow);
      this.$emit("nodes", this.nodes);
      this.resetPanel();
    },
    addWorkflowTrigger() {
      const newId = moment().valueOf().toString();
      let triggers = this.nodes.filter((n) => n.data.type === "addNewTrigger");
      let addTriggerIndex = triggers.findIndex((el) => el.data.add)
      const newPositionX =
        triggers.length > 0
          ? triggers[triggers.length - 1].position.x + 300
          : 0;
      let nodePayload = {
        id: newId,
        type: "custom",
        name: this.selectedData.name,
        label: this.selectedData.label,
        data: {
          title: this.flowTitle,
          icon: this.selectedData.icon,
          type: this.selectedFlow,
          add: false,
          name: this.selectedData.name,
        },
        showAddFilterBtn: this.selectedData?.showAddFilterBtn,
        hasFilters: this.selectedData?.hasFilters,
        filters: [...this.selectedData.filters],
        position: { x: newPositionX, y: this.defaultYPosition },
      };

      nodePayload = this.getUpdatedNodePayload(nodePayload);
      this.nodes.splice(addTriggerIndex, 0, nodePayload);
      console.log("newNode", nodePayload);
      let workflowTriggerPayload = this.getWorkflowTriggerPayload(nodePayload);
      this.workflow.trigger.events.push(workflowTriggerPayload);
      this.$emit("renderKey");
      this.$emit("stepAdded");
      this.$emit("workflow", this.workflow);
      this.$emit("nodes", this.nodes);
      this.$emit("connectEdges", "");
      this.resetPanel();
    },

    calculateChildPositions(parentX, nodeWidth, branches) {
      const totalWidth =
        (branches - 1) * (nodeWidth / 2) + branches * nodeWidth;
      const startX = parentX - (totalWidth - nodeWidth) / 2;
      const positions = [];
      for (let i = 0; i < branches; i++) {
        const childX = startX + i * (nodeWidth + nodeWidth / 2);
        positions.push(childX);
      }
      return positions;
    },

    addNewAction() {
      let nodePayload = {};
      let addNewActionPayload = {};
      let newId = moment().valueOf().toString();
      let addActionNodeIndex = this.nodes.findIndex(
        (n) => n.data.type === "addNewAction" && n.id == this.selectedNodeId
      );
      let findFirstAction = this.nodes.find(
        (n) => n.data.type === "addNewAction" && n.firstAction
      );
      nodePayload = {
        id: newId,
        type: "custom",
        name: this.selectedData.name,
        label: this.selectedData.label,
        data: {
          title: this.flowTitle,
          branchId: this.isBranchData?.branchId
            ? this.isBranchData.branchId
            : null,
          type: this.selectedFlow,
          icon: this.selectedData.icon,
          add: false,
          name: this.selectedData.name,
        },
        filters: [...this.selectedData.filters],
        hasFilters: this.selectedData?.hasFilters,
        position: {
          x: this.isBranchData
            ? this.isBranchData.position.x
            : this.defaultXPosition,
          y: this.nodes[addActionNodeIndex].position.y + this.addYPosition,
        },
      };
      // console.log('this.isBranchData ', this.isBranchData)
      // console.log('newNode ', nodePayload)

      if (!findFirstAction) {
        nodePayload = {
          ...nodePayload,
          firstAction: true,
        };
      }
      nodePayload = this.getUpdatedNodePayload(nodePayload);

      this.nodes.splice(addActionNodeIndex + 1, 0, nodePayload);
      if (this.selectedData?.name == "condition") {
        let xAxis = this.isBranchData
          ? this.isBranchData.position.x
          : this.defaultXPosition;
        let positions = this.calculateChildPositions(
          xAxis,
          250,
          this.selectedData?.branches.length
        );
        // console.log('positions ', positions)
        let findEndTriggerIndex = -1;
        if (this.isBranchData) {
          findEndTriggerIndex = this.nodes.findIndex(
            (n) =>
              n.data.branchId == this.isBranchData.branchId &&
              n.data.type === "EndTrigger"
          );
        } else {
          findEndTriggerIndex = this.nodes.findIndex(
            (n) => n.data.type === "EndTrigger"
          );
        }
        if (findEndTriggerIndex !== -1) {
          this.nodes.splice(findEndTriggerIndex, 1);
        }
        this.selectedData?.branches.forEach((el, idx) => {
          let branchPayload = {};
          let addIconActionPayload = {};
          let endActionPayload = {};
          branchPayload = {
            id: `branchPayload` + moment().valueOf().toString() + idx,
            type: "custom",
            name: el.branch_name,
            label: el.branch_name,
            data: {
              title: el.branch_name,
              type: "addNewAction",
              isBranch: true,
              isBranchLabelNode: true,
              parentId: nodePayload.id,
              branchId: el.id,
              icon: this.selectedData.icon,
              add: false,
              name: el.branch_name,
            },
            filters: [...this.selectedData.filters],
            hasFilters: this.selectedData?.hasFilters,
            position: {
              x: positions[idx],
              y: nodePayload.position.y + this.addYPosition,
            },
          };
          // console.log('payload nodes branchPayload', branchPayload)
          this.nodes.push(branchPayload);

          addIconActionPayload = {
            id: `addNewActionPayload` + moment().valueOf() + idx,
            type: "custom",
            position: {
              x: positions[idx],
              y: branchPayload.position.y + this.addYPosition,
            },
            data: {
              parentId: branchPayload.id,
              branchId: el.id,
              isBranch: true,
              label: "Add Action",
              type: "addNewAction",
              add: true,
              icon: "fa fa-plus",
            },
          };
          this.nodes.push(addIconActionPayload);

          endActionPayload = {
            id: `endActionPayload` + moment().valueOf() + idx,
            type: "custom",
            label: "END",
            position: {
              x: positions[idx],
              y: addIconActionPayload.position.y + this.addYPosition,
            },
            data: {
              parentId: addIconActionPayload.id,
              branchId: el.id,
              isBranch: true,
              label: "END",
              type: "EndTrigger",
              add: true,
            },
          };
          // console.log('endActionPayload :- ', endActionPayload)
          this.nodes.push(endActionPayload);
        });
      } else {
        addNewActionPayload = {
          id: `addNewActionPayload` + moment().valueOf(),
          type: "custom",
          position: {
            x: this.isBranchData
              ? this.isBranchData.position.x
              : this.defaultXPosition,
            y: nodePayload.position.y + this.addYPosition,
          },
          data: {
            label: "Add Action",
            branchId: this.isBranchData?.branchId
              ? this.isBranchData.branchId
              : null,
            type: "addNewAction",
            add: true,
            icon: "fa fa-plus",
            parentId: newId,
          },
        };
        this.nodes.splice(addActionNodeIndex + 2, 0, addNewActionPayload);
      }
      if (this.isBranchData) {
        const setYAxis = (branchId, init) => {
          const nodesInBranch = this.nodes.filter(
            (n) => n.data.branchId === branchId
          );
          nodesInBranch.forEach((el, i) => {
            if (
              el.data.type === "addNewAction" ||
              el.data.type === "EndTrigger"
            ) {
              if (el.name === "condition") {
                el.branches.forEach((branch) => {
                  setYAxis(branch.id, false);
                });
              }
              let findLastAddActionIndex = nodesInBranch.findIndex(
                (n) => n.id === addNewActionPayload.id
              );
              if (findLastAddActionIndex !== -1) {
                if (i > findLastAddActionIndex) {
                  el.position.y =
                    nodesInBranch[i - 1].position.y + this.addYPosition;
                }
              } else {
                if (!init) {
                  el.position.y = el.position.y + this.addYPosition;
                }
              }
            }
          });
        };
        setYAxis(this.isBranchData.branchId, true);
      } else {
        let filteredNodes = this.nodes.filter((n) => !n.data.branchId);
        filteredNodes.forEach((el, i) => {
          if (el.data.type == "addNewAction" || el.data.type == "EndTrigger") {
            let findLastAddActionIndex = filteredNodes.findIndex(
              (n) => n.id == addNewActionPayload.id
            );
            // console.log('findLastAddActionIndex :- ', findLastAddActionIndex)
            if (findLastAddActionIndex !== -1) {
              if (i > findLastAddActionIndex) {
                el.position.y =
                  filteredNodes[i - 1].position.y + this.addYPosition;
              } else {
                if (this.nodes.some((elem) => elem.data.branchId)) {
                  console.log("goin in branches");
                  this.nodes.forEach((el) => {
                    if (el.name == "condition") {
                      el.branches.forEach((branch) => {
                        let branchChild = this.nodes.filter(
                          (f) => f.data.branchId == branch.id
                        );
                        console.log("branchChild ", branchChild);
                        branchChild.forEach((n) => {
                          n.position.y = n.position.y + this.addYPosition;
                        });
                      });
                    }
                  });
                }
              }
            }
          }
        });
      }

      let workflowActionPayload = this.getWorkflowActionPayload(nodePayload);
      // let workflowActionIndex = this.workflow.branches[0].actions.findIndex(
      //   (el) => el.id == this.selectedNodeId
      // );
      let actionIndex = this.nodes.findIndex((el) => el.id == this.nextNodeId)

      console.log('actionIndex :', actionIndex)
      let actionIndexId = actionIndex !== -1 ?  this.nodes[actionIndex]?.id : null

      let workflowActionIndex =  actionIndexId ? this.workflow.branches[0].actions.findIndex(
        (el) => el.id == actionIndexId
      ) : -1;

      // console.log('tempactionIndexId ', tempactionIndexId)
      console.log("workflowActionIndex :- ", workflowActionIndex);
      // console.log("this.selectedData :- ", this.selectedData);
      if (workflowActionIndex !== -1) {
        this.workflow.branches[0].actions.splice(
          workflowActionIndex,
          0,
          workflowActionPayload
        );
      } else {
        if (nodePayload?.data?.branchId) {
          if (this.selectedData?.name === "condition") {
            let payload = this.selectedData.branches.map((el) => ({
              id: el.id,
              branch_name: el.branch_name,
              condition: el.condition,
              actions: [],
              branches: [],
            }));

            const addPayloadToBranch = (branches, branchId) => {
              for (let branch of branches) {
                if (branch.id === branchId) {
                  // Adding the new branches to the target branch
                  branch.branches = payload;
                  return true;
                }

                // Recurse into nested branches
                if (
                  branch.branches &&
                  addPayloadToBranch(branch.branches, branchId)
                ) {
                  return true;
                }

                // Check actions for nested branches
                if (branch.actions) {
                  for (let action of branch.actions) {
                    if (
                      action.branches &&
                      addPayloadToBranch(action.branches, branchId)
                    ) {
                      return true;
                    }
                  }
                }
              }
              return false;
            };

            addPayloadToBranch(
              this.workflow.branches,
              nodePayload.data.branchId
            );
          } else {
            const findAndPushAction = (branches, branchId, actionPayload) => {
              for (let branch of branches) {
                if (branch.id === branchId) {
                  branch.actions.push(actionPayload);
                  return true;
                }

                // Recurse into nested branches
                if (
                  branch.branches &&
                  findAndPushAction(branch.branches, branchId, actionPayload)
                ) {
                  return true;
                }

                // Check actions for nested branches
                if (branch.actions) {
                  for (let action of branch.actions) {
                    if (
                      action.branches &&
                      findAndPushAction(
                        action.branches,
                        branchId,
                        actionPayload
                      )
                    ) {
                      return true;
                    }
                  }
                }
              }
              return false;
            };

            findAndPushAction(
              this.workflow.branches,
              nodePayload.data.branchId,
              workflowActionPayload
            );
          }
        } else if (this.selectedData?.name === "condition") {
          let payload = this.selectedData.branches.map((el) => ({
            id: el.id,
            branch_name: el.branch_name,
            condition: el.condition,
            actions: [],
            branches: [],
          }));

          if (this.workflow.branches[0]?.branches) {
            this.workflow.branches[0].branches.push(...payload);
          } else {
            this.workflow.branches[0].branches = payload;
          }
        } else {
          this.workflow.branches[0].actions.push(workflowActionPayload);
        }
      }

      // console.log('this.workflow ', this.workflow)

      this.$emit("renderKey");
      this.$emit("stepAdded");
      this.$emit("workflow", this.workflow);
      this.$emit("nodes", this.nodes);
      this.resetPanel();
      this.$emit("connectEdges", "");

      const duplicates = this.findDuplicatesByX(this.nodes);

      const duplicatesGroup = _.groupBy(duplicates, (el) => el.data.branchId);
      this.reCalcaulatePositionX(duplicatesGroup);
    },

    findDuplicatesByX(arr) {
      let seenX = [];
      const duplicates = [];

      // console.log('seenX ', seenX)
      arr.forEach((obj) => {
        if (
          seenX.find(
            (n) =>
              n.x == obj.position.x.toString() &&
              n.y == obj.position.y.toString()
          )
        ) {
          duplicates.push(obj);
        }
        seenX.push({
          x: obj.position.x.toString(),
          y: obj.position.y.toString(),
        });
      });

      return duplicates;
    },

    recalculatePosition(obj, parentPosX) {
      if (obj.name == "condition") {
        console.log("condition :- ", obj);
        let positions = [];
        obj.branches.forEach((el, i) => {
          let nodes = this.nodes.filter((n) => n?.data?.branchId == el?.id);
          console.log("nodes ", nodes);
          if (nodes[nodes.length - 1].name == "condition") {
            positions = this.calculateChildPositions(
              parentPosX,
              250 * obj.branches.length,
              obj.branches.length
            );
          } else {
            positions = this.calculateChildPositions(
              parentPosX,
              250,
              obj.branches.length
            );
          }
          nodes.forEach((k) => {
            this.recalculatePosition(nodes[nodes.length - 1], positions[i]);
            k.position.x = positions[i];
          });
        });
      }
    },

    reCalcaulatePositionX(duplicatesGroup) {
      for (const key in duplicatesGroup) {
        if (Object.prototype.hasOwnProperty.call(duplicatesGroup, key)) {
          const element = duplicatesGroup[key];
          const getBranch = (findBranch) => {
            if (findBranch) {
              console.log("findBranch :- ", findBranch);
              let findParent = this.nodes.find((el) =>
                el?.branches?.some((n) => n?.id === findBranch?.data?.branchId)
              );

              if (findParent) {
                console.log("findParent :- ", findParent);
                let positions = this.calculateChildPositions(
                  findParent.position.x,
                  250 * findParent.branches.length,
                  findParent.branches.length
                );
                console.log("positions :- ", positions);

                findParent.branches.forEach((el, i) => {
                  let nodes = this.nodes.filter(
                    (n) => n?.data?.branchId == el?.id
                  );
                  nodes.forEach((k) => {
                    const findBranch = this.nodes.find(
                      (el) =>
                        el.id.toString() == k[0]?.data?.parentId?.toString()
                    );
                    getBranch(findBranch);
                    this.recalculatePosition(
                      nodes[nodes.length - 1],
                      positions[i]
                    );
                    k.position.x = positions[i];
                  });
                });
              }
            }
          };
          let findBranch = this.nodes.find(
            (el) => el.id.toString() == element[0]?.data?.parentId?.toString()
          );
          getBranch(findBranch);
        }
      }
    },

    addFlow(event) {
      this.selectedData = {...event}
      if (this.selectedFlow === "addNewTrigger") {
        if (this.isUpdate) {
          this.updateAction();
        } else {
          this.addWorkflowTrigger();
        }
      } else {
        if (this.isUpdate) {
          this.updateAction();
        } else {
          this.addNewAction();
        }
      }
    },

    selectWorkflow(event) {
      this.showSubPanel = true;
      this.flowTitle = this.formatLabel(event.label);
    },

    formatLabel(str) {
      return str
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, function (str) {
          return str;
        });
    },

    nodeClick(e) {
      if (e?.node?.data.isBranchLabelNode) {
        return;
      }
      const nodeData = { ...e?.node };
      const type = nodeData?.data?.type;
      // console.log("node :- ", e?.node);
      if (type === "addNewTrigger" || type === "addNewAction") {
        this.selectedFlow = type;
        this.$emit("selectedFlow", type);
        this.$emit("isOpen", true);
        this.showSubPanel = false;
        this.isUpdate = false;
        if (nodeData?.data?.add == false) {
          this.flowTitle = nodeData.data?.title;
          let payload = {
            id: nodeData?.id,
            type: nodeData?.type,
            name: nodeData?.name,
            label: nodeData?.label,
            data: nodeData?.data,
            hasFilters: nodeData?.hasFilters,
            filters: [...nodeData?.filters],
            position: nodeData?.position,
          };
          if (nodeData?.showAddFilterBtn !== undefined) {
            payload = {
              ...payload,
              showAddFilterBtn: nodeData?.showAddFilterBtn,
            };
          }
          if (nodeData?.name == "webhook") {
            payload = {
              ...payload,
              api_url: nodeData?.api_url,
              method: nodeData?.method,
              customData: nodeData?.customData,
              headers: nodeData?.headers,
            };
          } else if (nodeData?.name == "updateTicketStatus") {
            console.log("nodeData :- ", nodeData);
            payload = {
              ...payload,
              ticket_status_id: nodeData.ticket_status_id,
            };
          } else if (nodeData?.name == "fetchConversation") {
            payload = {
              ...payload,
              api_url: nodeData?.api_url,
              sendToModalSupport: nodeData?.sendToModalSupport,
              headers: nodeData?.headers,
            };
          } else if (nodeData?.name == "assignTicket") {
            payload = {
              ...payload,
              assignedUsers: nodeData?.assignedUsers,
              roundRobin: nodeData?.roundRobin,
            };
          } else if (nodeData?.name == "addTag") {
            payload = { ...payload, selectedTags: nodeData?.selectedTags };
          } else if (nodeData?.name == "removeTag") {
            payload = { ...payload, selectedTags: nodeData?.selectedTags };
          } else if (nodeData?.name == "createNote") {
            payload = { ...payload, note: nodeData?.note };
          } else if (nodeData?.name == "autoReply") {
            payload = {
              ...payload,
              subject: nodeData?.subject,
              message: nodeData?.message,
              replyType: nodeData?.replyType,
            };
          } else if (
            ["redirectEmail", "forwardEmail"].includes(nodeData?.name)
          ) {
            payload = { ...payload, email: nodeData?.email };
          } else if (["chatgpt", "gemini", "claude", "llama"].includes(nodeData?.name)) {
            payload = {
              ...payload,
              model: nodeData.model,
              prompt: nodeData.prompt,
              temperature: +nodeData.temperature,
            };
            if(["chatgpt", "claude", "llama"].includes(nodeData?.name)) {
              payload = {
                ...payload,
                top_p: +nodeData.top_p,
                max_tokens: +nodeData.max_tokens,
              };
            }
            if(['gemini'].includes(nodeData.name)) {
              payload = {
                ...payload,
                stopSequences:nodeData.stopSequences,
                maxOutputTokens:+nodeData?.maxOutputTokens
              }
            }
            if(["claude"].includes(nodeData?.name)) {
              payload = {
                ...payload,
                top_k: +nodeData.top_k,
              }
            }
          } else if (nodeData?.name == "condition") {
            payload = {
              ...payload,
              branches: nodeData.branches,
            };
          }
          this.selectedData = { ...payload };
          // console.log(" this.selectedData 1785", this.selectedData);
          this.showSubPanel = true;
          this.isUpdate = true;
        } else {
          if (nodeData?.data?.branchId) {
            this.isBranchData = {
              ...nodeData?.data,
              position: e?.node?.position,
              id: nodeData?.id,
            };
            console.log("isBranchData ", this.isBranchData);
          } else {
            this.isBranchData = null;
          }
          this.selectedData = {};
          this.flowTitle = "";
          this.isUpdate = false;
          this.selectedNodeId = nodeData?.id;
          let findIndex = this.nodes.findIndex((el) => el?.id == this.selectedNodeId)
          // console.log('findIndex ', findIndex)
          if(findIndex !== -1) {
            this.nextNodeId = this.nodes[findIndex+1].id
          } 
          this.selectedparentNodeId = nodeData?.data?.parentId || "";
        }
      }
    },

    resetPanel() {
      this.selectedData = {};
      this.isBranchData = null;
      this.showSubPanel = false;
      this.$emit("isOpen", false);
      this.flowTitle = "";
      this.isUpdate = false;
      this.$emit("centerAllTriggers");
      setTimeout(() => {
        this.fitViewNodes();
      }, 10);
    },
    fitViewNodes() {
      let findNodes = this.nodes.find((el) => !el.data.add);
        let findActionNodes = this.nodes.find((el) => el.data.type == 'addNewAction' && !el.data.add);
        if (!findActionNodes) {
          this.instance.fitView(this.$store.state.fitViewParams);
        } else if (!findNodes) {
          this.instance.fitView({ padding: 0.25, offset: { x: 0, y: -100 }, duration: 500 });
        } else {
          this.instance.fitView({ padding: 0.2 });
        }
    },
  },
  mounted() {
    this.nodes = this.workflowNodes;
    this.edges = this.workflowEdges;
    this.workflow = this.workflowJson;

    window.addEventListener("message", (event) => {
      // Check if the message is intended for setting the data_alert
      // console.log("event.data.data_alert ", event.data.data_alert);
      if (event.data.action === "setDataAlert") {
        this.is_alert = event.data.data_alert;

        // You can now use the dataAlert value inside the iframe
      }
    });
  },
  watch: {
    '$store.state.goToAction' : {
        deep:true,
        handler() {
          if(this.$store.state.goToAction) {
            let selectedFlow = this.nodes.find((el) => el.id == this.$store.state.goToAction)
            if(selectedFlow) {
              // this.$emit('selectedFlow', selectedFlow)
              this.$emit('isOpen', true)
              this.selectedData = { ...selectedFlow };
              this.showSubPanel = true;
              this.isUpdate = true;
              this.flowTitle = this.formatLabel(selectedFlow.label);
              setTimeout(() => {
                let posY = selectedFlow?.position?.y - 200
                  this.instance.fitView({minZoom:1, offset:{x:0, y:posY * -1 }, duration: 500 });
              }, 100)
            } 
          } else {
            this.fitViewNodes()
          }
        }
      },
    // activeTab() {
    //   this.fitViewNodes()
    // },
    isLoadingWorkflows() {
      if (this.instance) {
        this.fitViewNodes();
      }
    },
    workflowNodes: {
      handler() {
        this.nodes = this.workflowNodes;
        this.fitViewNodes();
        this.$emit("renderKey");
      },
    },
    workflowEdges: {
      handler() {
        this.edges = this.workflowEdges;
        this.$emit("renderKey");
      },
    },
    workflowJson: {
      deep: true,
      handler() {
        this.workflow = this.workflowJson;
        console.log("workflow : - ", this.workflow);
        // this.$emit("renderKey");
      },
    },
  },
};
</script>

<style>
.custom-swal-popup .swal2-icon.swal2-icon-show {
  margin: 0;
  border: 0;
  font-size: 4px;
  display: block !important;
  width: 100% !important;
  height: unset !important;
}

.custom-swal-popup .swal2-icon-content {
  justify-content: space-between !important;
  width: 100% !important;
}

.custom-popup-icon {
  background: #fee4e2;
  border: 8px solid #fef3f2;
  width: 38px !important;
  height: 38px !important;
  border-radius: 28px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.custom-swal-actions {
  display: flex !important;
  flex-direction: row-reverse !important; /* Reverses the button order */
  gap: 8px !important; /* Adds spacing between buttons */
  margin: 1.25em 0 0 0 !important;
  justify-content: end !important;
}

.custom-swal-actions .btn {
  padding: 0 14px !important;
  height: 40px !important;
}

.custom-swal-popup {
  border-radius: 8px !important;
  padding: 25px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.custom-swal-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #333 !important;
  margin-bottom: 10px !important;
  text-align: left !important;
}

.custom-swal-text {
  font-size: 14px !important;
  color: #666 !important;
  margin-bottom: 20px !important;
  text-align: left !important;
}

.btn-outline-secondary {
  border: 1px solid #ddd !important;
  color: #333 !important;
  padding: 6px 12px !important;
  border-radius: 4px !important;
}

.btn-outline-secondary:hover {
  background-color: #f1f1f1 !important;
}

.btn-danger {
  background-color: #dc3545 !important;
  color: white !important;
  padding: 6px 12px !important;
  border-radius: 4px !important;
  border: none !important;
}

.btn-danger:hover {
  background-color: #c82333 !important;
}
</style>
