<template>
  <popper @close:popper="(search = ''), (selectedCategory = '')" :class="type == 'note' ? '' : 'ms-auto'" @click="$event.stopPropagation()">
    <i
      class="fa cursor-pointer"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-trigger="hover"
      title="Available Values"
      :class="type == 'note' ? 'fa-regular text-sm fa-1x fa-tag mt-2' : 'fa-regular fa-tag fa-2x '"
      :style="type == 'note' ? 'font-weight: normal;' : ''"></i>
    <template #content="{ close }">
      <div class="popover-content bg-white rounded-3 shadow inbox-nav-manage-options" ref="popoverContent">
        <div class="col-12 container-fluid p-0">
          <ul class="list-unstyled text-xs p-1 mb-0 popli">
            <li class="bg-light text-sm d-flex align-items-center justify-content-between">
              <span class="p-2"> Available Values </span>
              <i class="fa p-2 cursor-pointer fa-times" @click="(search = ''), close()"></i>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div class="form-group w-100 mx-1">
                <i class="fa fa-search position-absolute" style="top: 9px; left: 18px"></i>
                <input type="text" v-model="search" class="form-control py-1 ps-4" placeholder="Search" />
              </div>
            </li>
          </ul>
          <ul class="list-unstyled text-sm p-1 mb-2 popli" style="max-height: 350px; min-width: 250px; overflow-y: scroll; scrollbar-width: auto !important">
            <template v-if="search">
              <template v-if="searchPlaceholder()?.length">
                <li @click="$emit('appendPlaceHolder', placeHolder.value), (search = ''), close()" v-for="(placeHolder, index) in searchPlaceholder()" :key="index" class="mx-1 cursor-pointer">
                  <div class="p-2 d-flex align-items-center justify-content-between hover-highlight">
                    <a class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis" href="javascript:void(0)">{{ placeHolder.category + ' / ' + placeHolder.label }}</a>
                  </div>
                </li>
              </template>
              <li v-else class="mx-1 cursor-pointer">
                <div class="p-2 d-flex align-items-center justify-content-center hover-highlight">
                  <a class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis" href="javascript:void(0)">No Result Found</a>
                </div>
              </li>
            </template>
            <template v-else-if="!selectedCategory">
              <li @click="selectedCategory = category" v-for="(placeHolder, category) in placeHolder" :key="category" class="mx-1 cursor-pointer">
                <div class="p-2 d-flex align-items-center justify-content-between hover-highlight">
                  <a
                    class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis"
                    href="javascript:void(0)"
                    :class="category.toLowerCase() === 'ai' ? 'text-uppercase' : 'text-capitalize'"
                    >{{ category }}</a
                  >
                  <i class="fa fa-chevron-right text-xs ms-2"></i>
                </div>
              </li>
            </template>
            <template v-else>
              <li class="bg-light text-sm d-flex align-items-center border-bottom">
                <i class="fa p-2 cursor-pointer fa-arrow-left text-primary fs-normal" @click="selectedCategory = ''"></i>
                <span class="p-2" :class="selectedCategory.toLowerCase() === 'ai' ? 'text-uppercase' : 'text-capitalize'">
                  {{ selectedCategory }}
                </span>
              </li>
              <li @click="$emit('appendPlaceHolder', placeHolder.value), (search = ''), close()" v-for="(placeHolder, index) in placeHolder[selectedCategory]" :key="index" class="mx-1 cursor-pointer">
                <div class="p-2 d-flex align-items-center justify-content-between hover-highlight">
                  <a class="text-col-navlink text-sm text-decoration-none text-nowrap text-ellipsis" href="javascript:void(0)">{{ placeHolder.label }}</a>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </popper>
</template>
<script>
  export default {
    name: 'PlaceHolders',
    props: ['type'],
    data() {
      return {
        search: '',
        selectedCategory: '',
        placeHolder: {
          Ai: [
            // { label: 'GPT Response', value: '{{gpt.response}}', category: 'Ai' },
            { label: 'OpenAI Response', value: '{{ai.openai_response}}', category: 'Ai' },
            { label: 'Gemini Response', value: '{{ai.gemini_response}}', category: 'Ai' },
            { label: 'Claude Response', value: '{{ai.claude_response}}', category: 'Ai' },
            { label: 'Llama Response', value: '{{ai.llama_response}}', category: 'Ai' }
          ],
          Agent: [
            { label: 'Name', value: '{{agent.name}}', category: 'Agent' },
            { label: 'Email', value: '{{agent.email}}', category: 'Agent' }
          ],
          Company: [
            { label: 'Name', value: '{{company.name}}', category: 'Company' },
            { label: 'Phone', value: '{{company.phone}}', category: 'Company' },
            { label: 'Email', value: '{{company.email}}', category: 'Company' },
            { label: 'Copyright', value: '{{company.copyright}}', category: 'Company' },
            { label: 'Website', value: '{{company.website}}', category: 'Company' },
            { label: 'Widget Url', value: '{{company.widget_url}}', category: 'Company' },
            { label: 'Logo', value: '{{company.logo}}', category: 'Company' },
            { label: 'IOS App Url', value: '{{company.ios}}', category: 'Company' },
            { label: 'Andorid App Url', value: '{{company.android}}', category: 'Company' },
            { label: 'Zapier App Url', value: '{{company.zapier}}', category: 'Company' },
            { label: 'Mobile App Name', value: '{{company.mobileapp_name}}', category: 'Company' },
            { label: 'Zapier App Name', value: '{{company.zapierapp_name}}', category: 'Company' },
          ],
          Contact: [
            { label: 'First Name', value: '{{contact.first_name}}', category:'Contact'},
            { label: 'Last Name', value: '{{contact.last_name}}', category:'Contact'},
            { label: 'Full Name', value: '{{contact.full_name}}', category: 'Contact' },
            { label: 'Email', value: '{{contact.email}}', category:'Contact'},
          ],
          Message: [
            { label: 'Message Subject', value: '{{message.subject}}', category: 'Message' },
            { label: 'Transcript', value: '{{message.transcript}}', category: 'Message' },
            { label: 'Last Message', value: '{{message.last}}', category: 'Message' },
            { label: 'Message Channel', value: '{{message.channel}}', category: 'Message' },
            // { label: 'Sender', value: '{{message.sender}}', category:'Message'},
            { label: 'Sender Full Name', value: '{{message.sender_fullname}}', category:'Message'},
            { label: 'Sender Email', value: '{{message.sender_email}}', category:'Message'},
            { label: 'Sender First Name', value: '{{message.sender_firstname}}', category:'Message'},
            { label: 'Sender Last Name', value: '{{message.sender_lastname}}', category:'Message'},
            { label: 'Start Time', value: '{{message.start_time}}', category:'Message'},
            { label: 'End Time', value: '{{message.end_time}}', category:'Message'},
            { label: 'Duration', value: '{{message.duration}}', category:'Message'},
            { label: 'Queue Duration', value: '{{message.queue_duration}}', category:'Message'},
            { label: 'Conversation Closed By', value: '{{message.closed_by}}', category:'Message'},
            { label: 'CSAT Score', value: '{{message.csat_score}}', category:'Message'},
            { label: 'CSAT Message', value: '{{message.csat_message}}', category:'Message'},
            { label: 'ID', value: '{{message.id}}', category:'Message'},
            { label: 'Starting URL', value: '{{message.starting_url}}', category:'Message'},
            { label: 'Referring URL', value: '{{message.referring_url}}', category:'Message'},
          ],
        },
        placeHolders: [],
      };
    },
    $emits: ['appendPlaceHolder'],
    methods: {
      searchPlaceholder() {
        let searchResults = [];
        for (const key in this.placeHolder) {
          const element = this.placeHolder[key];
          let results = element.filter((el) => el.label.toLowerCase().includes(this.search.toLowerCase()));
          console.log('results ', results);
          searchResults = [...searchResults, ...results];
        }
        return searchResults;
      },
    },
  };
</script>

<style scoped>
  ::v-deep .popper .hover-highlight:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
    border-radius: 4px !important;
  }
</style>
