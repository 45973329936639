<template>
  <div>
    <!-- <Handle type="target" :position="Position.Top" /> -->
    <div class="custom-node nodes add-new-trigger" v-if="data?.type == 'addNewTrigger' && data?.add">
      <span class="custom-node-label d-flex align-items-center">
        <span class="btn mb-0 add-trigger-btn me-2">
          <i :class="data.icon"></i>
        </span>
        <span class="text-ellipsis"> {{ data.title || label }}</span>
      </span>
    </div>
    <div class="custom-node nodes" :class="selectedData?.id == id ? 'active' : ''" v-else-if="data?.type == 'addNewTrigger' && !data?.add">
      <span class="custom-node-label d-flex align-items-center">
        <span class="btn mb-0 bg-light add-trigger-btn me-2"> <i :class="data.icon"></i></span>
        <span class="text-ellipsis custom-node-label-inner">
          <div class="text-custom font-600">Trigger</div>
          <div class="">{{ data.title || label }}</div>
        </span>
      </span>
    </div>
    <div class="custom-node end-trigger" v-else-if="data?.type == 'EndTrigger'">
      <span class="custom-node-label">
        <span class="border custom-node-label-inner" style="padding: 4px 14px; border-radius: 40vh"> {{ label }}</span>
      </span>
    </div>
    <div v-else class="custom-node" :class="data?.type == 'addNewAction' && data?.add ? 'add-action' : selectedData?.id == id ? 'active nodes ' : 'nodes'">
      <span class="custom-node-label" style="" v-if="data?.type == 'addNewAction' && data?.add">
        <span class="bg-white bordercustom-node-label-inner d-flex align-items-center justify-content-center" style="width: 25px; height: 25px; border-radius: 50%"> <i :class="data.icon"></i> </span>
      </span>
      <span class="custom-node-label d-flex align-items-center" v-else>
        <span class="text-ellipsis custom-node-label-inner">
          {{ data.title || label }}
        </span>
      </span>
    </div>
    <!-- <Handle type="source" :position="Position.Bottom" /> -->
  </div>
</template>

<script>
  export default {
    name: 'CustomNode',
    props: ['id', 'data', 'label', 'selectedData', 'name', 'position'],
    emits: ['deleteAction'],
  };
</script>

<style scoped>
  .custom-node {
    font-size: 14px;
    padding: 14px 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #e4e7eb;
  }
  .custom-node.nodes {
    width: 250px;
  }
  .custom-node-label {
    font-size: 14px;
  }
  .add-new-trigger {
    border: 1px dashed #2463eb !important;
    color: #2463eb;
    font-weight: 600;
    background: #eef6ff;
  }
  .add-trigger-btn i {
    color: #2463eb;
  }
  .add-new-trigger .add-trigger-btn {
    background-color: #e4e7eb !important;
  }
  .add-action {
    height: 25px;
    width: 250px;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end-trigger {
    width: 250px;
    background: transparent;
    border: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .end-trigger .custom-node-label-inner {
    background: #d1d5da !important;
  }
  .text-custom {
    color: #2463eb;
  }
  .font-600 {
    font-weight: 600;
  }
  .add-trigger-btn {
    width: 40px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #f1f4f7;
    font-size: 16px;
  }
  .action-btn {
    color: #9ba3af;
  }
  .active {
    border: 1px solid #2463eb !important;
  }
</style>
