// import { useStore } from "vuex";
const axios = require("axios");
let inboxBaseURL = process.env.VUE_APP_INBOX_API_BASE_URL;
let logEventsBaseUrl =  process.env.VUE_APP_EVENT_HISTORY_URL
// import * as sweetalert from "sweetalert";



const inboxInstance = axios.create({
    baseURL: inboxBaseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});

const logEventsInstance = axios.create({
    baseURL: logEventsBaseUrl,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "",
    },
});

// inboxInstance.interceptors.request.use((config) => {
//     const store = useStore()
//     // console.log('store ', store)
//     const token = store.state.token
//     // console.log('token ', token)
//     if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
//     config.validateStatus = (status) => status < 400;
//     return config;
// });


inboxInstance.interceptors.response.use(
    (successRes) => {
       
        // console.log('successRes', successRes)
        return successRes;
    },
    (error) => {
        // console.log('error', error)
        // sweetalert({
        //     title: "Error!",
        //     text: error?.response?.data?.message
        //         ? error?.response?.data?.message
        //         : "An error occurred",
        //     icon: "error",
        //     buttons: {
        //         confirm: {
        //             text: "Close",
        //             value: true,
        //             visible: true,
        //             className: "btn bg-gradient-danger mx-auto",
        //             closeModal: true,
        //         },
        //     },
        // });
        console.log("caught error", error?.response?.status);
       
        return Promise.reject(error);
    }
);


const Api = {
    getAgentList:async (params, token) => {
        let url = `/agents?support_lib_id=${params.support_lib_id}`
        if(params.parent_lib_id) {
            url += `&parent_lib_id=${params.parent_lib_id}&include_parent_lib_id=1`
        }
        if(params.rt) {
            url += `&rt=${params.rt}`
        }
        if(params.mode) {
            url += `&mode=${params.mode}`
        }
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
          }
        return await inboxInstance.get(url, {headers:headers});
    },

    fetchTags:async (params, token) => {
        let url = `conv_tags?support_lib_id=${params.support_lib_id}`
        if(params?.conv_ref) {
            url += `&conv_ref=${params.conv_ref}`
        }
        if(params?.show_all) {
            url += `&show_all=1`
        }
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
          }
        return await inboxInstance.get(url, {headers:headers})
    },
    addTags:async(payload, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.post(`conv_tags`, payload, {headers:headers})
    },

    listChildLibraries:async(params, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.get(`agency?support_lib_id=${params.support_lib_id}&list_children=1`,  {headers:headers})
    },

    getTicketStatuses:async(support_lib_id, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.get(`ticket_statuses?support_lib_id=${support_lib_id}`, {headers:headers})
    },
   
    getWorkflows:async (params, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        let url = `workflows?support_lib_id=${params.support_lib_id}`;
        if(params?.workflow_ref) {
            url += `&workflow_ref=${params?.workflow_ref}`
        }
        if(params?.is_enabled !== undefined) {
            url += `&is_enabled=${params?.is_enabled}`
        }
        if(params?.workflow_folder_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        return await inboxInstance.get(url, {headers:headers})
    },
    createWorkflow:async (payload, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.post(`workflows`, payload, {headers:headers})
    },
    updateWorkflow:async(payload, params, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.put(`workflows?workflow_ref=${params.workflow_ref}`, payload, {headers:headers})
    },
    deleteWorkflow:async(payload, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.delete(`workflows`, {data:payload}, {headers:headers})
    },
    getWorkflowFolders:async (params, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        let url = `workflow_folders?support_lib_id=${params.support_lib_id}`;
        if(params?.workflow_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        if(params?.workflow_folder_ref) {
            url += `&workflow_folder_ref=${params?.workflow_folder_ref}`
        }
        return await inboxInstance.get(url, {headers:headers})
    },
    createWorkflowFolder:async (payload, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.post(`workflow_folders`, payload, {headers:headers})
    },
    updateWorkflowFolder:async(payload, params, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.put(`workflow_folders?workflow_folder_ref=${params.workflow_folder_ref}`, payload, {headers:headers})
    },
    deleteWorkflowFolder:async(payload, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.delete(`workflow_folders`, {data:payload}, {headers:headers})
    },
    
    getModalWidgetDetails:async (params, token) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            'Authorization': `Bearer ${token}`,
        }
        return await inboxInstance.get(`modal_widgets?support_lib_id=${params.support_lib_id}`, {headers:headers})
    },

    getLogEvents:async(params) => {
        let url = `EventHistory`;
        if(params?.workflow_ref) {
            url += `/${params?.workflow_ref}/1`;
        }
        if(params?.page) {
            url += `?page=${params?.page}`
        }
        if(params?.pageSize) {
            url += `&pageSize=${params?.pageSize}`
        }
        return await logEventsInstance.get(url)
    }
}

export default Api;
