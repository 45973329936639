<template>
    <div v-if="isLoading" class="loading-overlay d-flex position-absolute bg-white align-items-center justify-content-center">
      Loading... <i class="fa fa-circle-o-notch fa-spin mx-1"></i>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>

  <style >
    .loading-overlay {
      width: 100%; 
      height: 100vh; 
      z-index: 999;
    }
  </style>