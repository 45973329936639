<template>
    <div class="pagination-container my-2">
      <div v-if="currentPageReport" class="currentPageReport">
        Showing {{ startRecord }} to {{ endRecord }} of {{ totalRecords }}
      </div>
      <nav aria-label="Pagination Navigation" v-if="totalPages > 1">
        <ul class="pagination mb-0">
          <!-- First Page Button -->
          <li
            :class="{ disabled: currentPage === 1 }"
            @click="changePage(1)"
          >
            <span>&laquo;</span> <!-- Double left arrow for first page -->
          </li>
  
          <!-- Previous Page Button -->
          <li
            :class="{ disabled: currentPage === 1 }"
            @click="changePage(currentPage - 1)"
          >
            <span>&lsaquo;</span> <!-- Single left arrow for previous -->
          </li>
  
          <!-- Pagination Links -->
          <li
            v-for="page in paginatedLinks"
            :key="page"
            :class="{ active: currentPage === page, 'dots': page === '...' }"
            @click="page !== '...' && changePage(page)"
          >
            <span>{{ page }}</span>
          </li>
  
          <!-- Next Page Button -->
          <li
            :class="{ disabled: currentPage === totalPages }"
            @click="changePage(currentPage + 1)"
          >
            <span>&rsaquo;</span> <!-- Single right arrow for next -->
          </li>
  
          <!-- Last Page Button -->
          <li
            :class="{ disabled: currentPage === totalPages }"
            @click="changePage(totalPages)"
          >
            <span>&raquo;</span> <!-- Double right arrow for last page -->
          </li>
        </ul>
      </nav>
  
      <!-- Per Page Dropdown positioned at the end -->
      <div class="per-page-select">

        <Select v-model="perPage" @change="onPerPageChange" :options="perPageOptions" placeholder="Select" class="w-100 p-0" />
        <!-- <select class="form-control" id="perPage" v-model="perPage" @change="onPerPageChange">
          <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
        </select> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Pagination",
    props: {
      currentPageReport:{
        type:Boolean,
        default:false
      },
      totalRecords: {
        type: Number,
        required: true,
      },
      pageSize: {
        type: Number,
        default: 10,
      },
      currentPage: {
        type: Number,
        required: true,
      },
      maxLinks: {
        type: Number,
        default: 5,
      },
      perPageOptions: {
        type: Array,
        default: () => [5, 10, 20, 50, 100], // Default options if not provided
      },
    },
    data() {
      return {
        perPage: this.pageSize,
      };
    },
    computed: {
      startRecord() {
        return (this.currentPage - 1) * this.perPage + 1;
      },
      endRecord() {
        return Math.min(this.currentPage * this.perPage, this.totalRecords);
      },
      totalPages() {
        return Math.ceil(this.totalRecords / this.perPage);
      },
      paginatedLinks() {
        const pages = [];
        const halfMaxLinks = Math.floor(this.maxLinks / 2);
        const startPage = Math.max(2, this.currentPage - halfMaxLinks);
        const endPage = Math.min(this.totalPages - 1, this.currentPage + halfMaxLinks);
  
        if (startPage > 2) {
          pages.push(1, '...');
        } else if (startPage === 2) {
          pages.push(1);
        }
  
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
  
        if (endPage < this.totalPages - 1) {
          pages.push('...', this.totalPages);
        } else if (endPage === this.totalPages - 1) {
          pages.push(this.totalPages);
        }
  
        return pages;
      },
    },
    methods: {
      changePage(page) {
        if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
          this.$emit("update:pagination", { page, per_page: this.perPage });
        }
      },
      onPerPageChange() {
        // Reset to the first page on perPage change and emit updated pagination data
        this.$emit("update:pagination", { page: 1, per_page: this.perPage });
      },
    },
    watch: {
      pageSize(newVal) {
        this.perPage = newVal;
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: end;

  }
  .per-page-select {
    margin-left: 1rem;
  }
  .pagination {
    display: flex;
    list-style: none;
    align-items: center;
    padding: 0;
  }
  .pagination li, .currentPageReport {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    margin: 0 2px;
    color:#64748b;
    background: var(--base-white) !important;
    min-width: 2.5rem !important;
    height: 2.5rem !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .pagination li.disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  .pagination li.active {
    background-color: #007bff;
    color: white;
    border-radius: 4px !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    width: 32px !important;
    height: 32px !important;
  }
  .pagination li.dots {
    cursor: default;
  }
  @media (max-width:991px) {
    .currentPageReport {
      display: none;
    }
  }
  </style>
  