import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import PrimeVue from 'primevue/config';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"
import Aura from '@primevue/themes/aura';
import Popper from "vue3-popper";
import store from './store';
import '@/assets/custom_global_chatinbox.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import InputText from 'primevue/inputtext';
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import Paginator from 'primevue/paginator';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

let appInstance;

appInstance = createApp(App)

appInstance.use(router)
appInstance.use(store)

appInstance.component('Select', Select);
appInstance.use(VueSweetalert2);
appInstance.component('MultiSelect', MultiSelect);
appInstance.component('InputText', InputText);
appInstance.component('AutoComplete', AutoComplete);
appInstance.component('Textarea', Textarea);
appInstance.component('Paginator', Paginator);
appInstance.component('VueDatePicker', VueDatePicker);
appInstance.component("Popper", Popper);
appInstance.use(Toast, {
  transition: "Vue-Toastification__bounce",
  newestOnTop: false
});
appInstance.use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        darkModeSelector: 'none',
    }
    }
  });
appInstance.mount('#app')
