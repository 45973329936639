<template>
    <div>
        <div class="text-white" v-html="title"></div>
        <span><small v-html="description"></small></span>
    </div>
</template>
<script>
    export default {
        name:'CustomToast',
        props:['title', 'description']
    }
</script>